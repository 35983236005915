<template>
  <div class="main-box">
    <a-button type="primary" class="add-btn" @click.stop="addStatusChange()">
      {{ addStatus? '结束':'添加点位' }}
    </a-button>
    <div id="container" class="map" />
    <a-modal
      title="上传该点数据"
      :visible="visible"
      :mask-closable="false"
      :confirm-loading="loading.add"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:4 }" :wrapper-col="{span: 18}">
          <a-form-item label="经度">
            <a-input id="longitude" v-model="param.longitude" placeholder="请输入经度" />
          </a-form-item>
          <a-form-item label="纬度">
            <a-input id="latitude" v-model="param.latitude" placeholder="请输入纬度" />
          </a-form-item>
          <a-form-item label="名称">
            <a-input id="title" v-model="param.title" placeholder="请输入纬度" />
          </a-form-item>
          <a-form-item label="地址">
            <a-input id="address" v-model="param.address" placeholder="请输入纬度" />
          </a-form-item>

          <a-form-item label="类型">
            <a-select @change="handleTypeChange">
              <a-select-opt-group v-for="(item,idx) in typeList" :key="idx" :label="item.name">
                <a-select-option v-for="(it,index) in item.children" :key="index" :value="it.id">
                  {{ it.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-item>
          <a-form-item label="封面">
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="/prod-api/panorama/upload"
              @change="handleChange"
            >
              <img v-if="param.cover" :src="imgPreUrl+param.cover" style="width:100px;heigh:100px;" alt="avatar">
              <div v-else>
                <a-icon :type="loading.invoiceLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传封面
                </div>
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      :title="showPoint.title"
      :visible="pointModal"
      :mask-closable="false"
      :footer="null"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:4 }" :wrapper-col="{span: 18}">
          <a-form-item label="经度">
            <a-input id="longitude" v-model="showPoint.longitude" disabled />
          </a-form-item>
          <a-form-item label="纬度">
            <a-input id="latitude" v-model="showPoint.latitude" disabled />
          </a-form-item>
          <a-form-item label="类型">
            <a-input id="typeName" v-model="showPoint.typeName" disabled />
          </a-form-item>
          <a-form-item label="名称">
            <a-input id="title" v-model="showPoint.title" disabled />
          </a-form-item>
          <a-form-item label="地址">
            <a-input id="address" v-model="showPoint.address" disabled />
          </a-form-item>
          <a-form-item label="封面">
            <img :src="imgPreUrl+showPoint.cover" style="width:100px;heigh:100px;" alt="avatar">
          </a-form-item>
          <a-form-item label="作品集">
            <a
              v-for="(item,idx) in showPoint.panoramaList"
              :key="idx"
              style="margin-right:10px"
              :href="item.url"
            >
              <img
                :src="imgPreUrl+item.cover"
                style="width:100px;heigh:100px;"
                alt="avatar"
              >
            </a>

          </a-form-item>
        </a-form>
        <div class="modal-btn">
          <a-button icon="arrow-up" @click="handleUpPan(showPoint.id)">上传全景图</a-button>
        </div>
      </div>
    </a-modal>

    <a-modal
      title="上传该点全景图"
      :visible="panoramaModal"
      :mask-closable="false"
      :confirm-loading="loading.upLoading"
      @ok="handleUpPanorama"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:4 }" :wrapper-col="{span: 18}">
          <a-form-item label="封面">
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="/prod-api/panorama/upload"
              @change="handleUpChange"
            >
              <img v-if="panoramaParam.cover" :src="imgPreUrl+panoramaParam.cover" style="width:100px;heigh:100px;" alt="avatar">
              <div v-else>
                <a-icon :type="loading.upLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传封面
                </div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="地址">
            <a-input id="url" v-model="panoramaParam.url" placeholder="全景图地址" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import panoramaApi from '../../api/panorama'
import typeApi from '../../api/type'
export default {
  data() {
    return {
      loading: {
        point: false,
        add: false,
        invoiceLoading: false,
        upLoading: false
      },
      panoramaModal: false,
      addStatus: false,
      pointModal: false,
      visible: false,
      addClickFun: {},
      map: {},
      imgPreUrl: '',
      param: {
        longitude: '',
        latitude: '',
        title: '',
        address: '',
        cover: '',
        panoramaList: []
      },
      panoramaParam: {
        cover: '',
        url: '',
        pointId: undefined
      },
      showPoint: {
        id: '',
        longitude: '',
        latitude: '',
        title: '',
        address: '',
        cover: ''
      },
      typeList: [],
      markers: {},
      markerList: []
    }
  },
  created() {
    this.fetchData()
    this.getImgPreUrl()
  },
  mounted() {
  },
  methods: {
    fetchData() {
      this.loading.point = true
      var that = this
      panoramaApi.getPointList().then(res => {
        if (res.status === 200) {
          res.data.forEach(element => {
            that.markerList.push({
              id: element.id,
              position: new window.TMap.LatLng(element.latitude, element.longitude)
            })
          })
          that.init()
        }
      }).finally(() => { this.loading.point = false })
      typeApi.getPointTypeList().then(res => {
        this.typeList = res.data
      }).finally(_ => { this.loading = false })
    },
    init() {
      var center = new window.TMap.LatLng(38.856428, 121.525456)
      // 初始化地图
      var map = new window.TMap.Map('container', {
        zoom: 18, // 设置地图缩放级别
        center: center // 设置地图中心点坐标
      })
      this.map = map
      this.createMarker(map)
    },
    createMarker(map) {
      const that = this
      var marker = new window.TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          marker: new window.TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 } // 描点位置
          })
        },
        geometries: that.markerList
      })
      var clickHandler = function(evt) {
        panoramaApi.getPointById(evt.geometry.id).then(res => {
          if (res.status === 200) {
            that.pointModal = true
            that.showPoint = res.data
          }
        })
      }
      marker.on('click', clickHandler)
      that.markers = marker
    },
    handleOk() {
      const that = this
      this.loading.add = true
      panoramaApi.addPoint(this.param).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.visible = false
          that.markers.add({
            id: res.data.id,
            position: new window.TMap.LatLng(res.data.latitude, res.data.longitude)
          })
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => { this.loading.add = false })
    },
    handleCancel() {
      this.visible = false
      this.pointModal = false
      this.panoramaModal = false
      this.param = {
        longitude: '',
        latitude: '',
        title: '',
        address: '',
        cover: ''
      }
      this.panoramaParam = {
        cover: '',
        url: '',
        pointId: undefined
      }
    },
    handleChange(data) {
      if (data.file.status === 'uploading') {
        this.loading.invoiceLoading = true
      } else if (data.file.status === 'done') {
        if (data.file.response.status === 200) {
          this.param.cover = data.file.response.data.path
        } else {
          this.$message.error(data.file.response.msg)
        }
        this.loading.invoiceLoading = false
      }
    },
    handleUpChange(data) {
      if (data.file.status === 'uploading') {
        this.loading.upLoading = true
      } else if (data.file.status === 'done') {
        if (data.file.response.status === 200) {
          this.panoramaParam.cover = data.file.response.data.path
        } else {
          this.$message.error(data.file.response.msg)
        }
        this.loading.upLoading = false
      }
    },
    getImgPreUrl() {
      this.imgPreUrl = window.location.href.split('#')[0] + 'prod-api/static/'
    },
    addStatusChange() {
      this.addStatus = !this.addStatus
      const that = this
      if (this.addStatus) {
        that.addClickFun = function(evt) {
          that.visible = true
          var lat = evt.latLng.getLat().toFixed(6)
          var lng = evt.latLng.getLng().toFixed(6)
          that.param.longitude = lng
          that.param.latitude = lat
        }
        // Map实例创建后，通过on方法绑定点击事件
        that.map.on('click', that.addClickFun)
      } else {
        console.log(111)
        that.map.off('click', that.addClickFun)
      }
    },
    handleUpPan(id) {
      this.pointModal = false
      this.panoramaModal = true
      this.panoramaParam.pointId = id
    },
    handleUpPanorama() {
      const that = this
      this.loading.upLoading = true
      panoramaApi.addPanorama(this.panoramaParam).then(res => {
        if (res.status === 200) {
          that.$message.success(res.msg)
          that.panoramaModal = false
        } else {
          that.$message.error(res.msg)
        }
      }).finally(() => { this.loading.upLoading = false })
    }
  }
}
</script>
<style scoped>
.main-box{
}
.map{
  width: 100%;
  height: 780px;
}
.add-btn{
  margin: 20px;
}
.form-label{
}
.modal-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
