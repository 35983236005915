import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/admin/api/list',
    method: 'get',
    params
  })
}
export function addPoint(data) {
  return request({
    url: '/panorama/addPoint',
    method: 'post',
    data
  })
}

export function addPanorama(data) {
  return request({
    url: '/panorama/addPanorama',
    method: 'post',
    data
  })
}

export function getPointList() {
  return request({
    url: '/panorama/getPointList',
    method: 'get'
  })
}
export function getPointById(id) {
  return request({
    url: `/panorama/getPointById/${id}`,
    method: 'get'
  })
}

const panoramaApi = {
  addPoint,
  getPointList,
  getPointById,
  addPanorama
}

export default panoramaApi
