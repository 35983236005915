import request from '@/utils/request'

export function getPointTypeList() {
  return request({
    url: '/panorama/getPointTypeList',
    method: 'get'
  })
}

export function addPointType(data) {
  return request({
    url: '/panorama/addPointType',
    method: 'post',
    data
  })
}

export function upPointType(data) {
  return request({
    url: '/panorama/upPointType',
    method: 'post',
    data
  })
}

export function delPointTypeById(id) {
  return request({
    url: `/panorama/delPointTypeById/${id}`,
    method: 'delete'
  })
}
const menuApi = {
  getPointTypeList,
  addPointType,
  upPointType,
  delPointTypeById
}

export default menuApi
